export const API_ROOT = {
  development: 'http://api-test.voicefrd.com',
  development2: 'http://api-test.voicefrd.com',
  test: 'http://api-test.voicefrd.com',
  test2: 'http://api-test.voicefrd.com',
  production: 'http://api.hazaclub.com',
  production2: 'http://api-in.kissoclub.com',
}[ENVIRONMENT]

export const API_PREFIX = '/api'
