import React, { useState, useEffect } from "react";
import "./index.scss"; // 需要自定义样式

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // 每 3 秒切换一次
        return () => clearInterval(timer);
    }, [images.length]);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
      <div className="carousel">
        <div
          className="carousel__slides"
          style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                }}
        >
          {images.map((img, index) => (
              // eslint-disable-next-line react/no-array-index-key
            <div className="carousel__slide" key={`slide-${index}`}>
              <img src={img.src} alt={`Slide ${index}`} />
            </div>
                ))}
        </div>
        <div className="carousel__dots">
          {images.map((id, index) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <span
              key={id}
              className={`carousel__dot ${
                            currentIndex === index ? "active" : ""
                        }`}
              onClick={() => goToSlide(index)}
            />
                ))}
        </div>
      </div>
    );
};

export default Carousel;
